
import React from 'react';

const FormularioAvancado = ({ formData, handleChange, handleCheckboxChange, handleSubmit, prevStep }) => {
    
    return (
        <form onSubmit={handleSubmit}>

            <h1>FALE CONOSCO</h1>
            <div>
                <label>Quantos alvos serão investigados:</label>
                <input type="number" placeholder='Insira o número de alvos, ex.: 1, 2, ...' name="alvos" value={formData.alvos} onChange={handleChange} required />
            </div>
            <div>
                <label>Os alvos são pessoas físicas ou jurídicas?</label>
                <input type="text" name="qualidadePessoal"  value={formData.qualidadePessoal} onChange={handleChange} required />
            </div>
            <div>
                <label>Nome completo do(s) alvo(s):</label>
                <input type="text" name="nomeAlvos" value={formData.nomeAlvos} onChange={handleChange} required />
            </div>
            <div>
                <label>CPF ou CNPJ do(s) investigado(s):</label>
                <input type="text" name="cpfCnpj" value={formData.cpfCnpj} onChange={handleChange} required />
            </div>
            <div className='checkbox-list-container'>
                <label>Marque as opções que mais te interessam saber sobre o(s) alvo(s):</label>
                <div>
                    <input type="checkbox" name="Pessoal" onChange={handleCheckboxChange} checked={formData.interesses.includes("Pessoal")} /> Pessoal: Nome, parentes, endereço, e-mail, telefone, etc.
                </div>
                <div>
                    <input type="checkbox" name="Financeiro" onChange={handleCheckboxChange} checked={formData.interesses.includes("Financeiro")} /> Financeiro: Faixa de renda, profissão, inscrições em CNPJ, nível de movimentação financeira.
                </div>
                <div>
                    <input type="checkbox" name="Credito" onChange={handleCheckboxChange} checked={formData.interesses.includes("Credito")} /> Crédito: Pendências financeiras, protestos de títulos, cartório, Score de crédito, etc.
                </div>
                <div>
                    <input type="checkbox" name="Juridico" onChange={handleCheckboxChange} checked={formData.interesses.includes("Juridico")} /> Jurídico: Lista de processos, antecedentes criminais, risco jurídico, etc.
                </div>
                <div>
                    <input type="checkbox" name="Administrativo" onChange={handleCheckboxChange} checked={formData.interesses.includes("Administrativo")} /> Administrativo: Lista de ocorrências ou certidões nada consta nos diversos órgãos administrativos do país.
                </div>
                <div>
                    <input type="checkbox" name="Bens" onChange={handleCheckboxChange} checked={formData.interesses.includes("Bens")} /> Bens: lista de bens móveis e imóveis localizados no território brasileiro.
                </div>
                <div>
                    <input type="checkbox" name="Outro" onChange={handleCheckboxChange} checked={formData.interesses.includes("Outro")} /> Outro:
                </div>
            </div>

            <div>
                <label>Toda a informação adicional que você possui sobre o(s) alvo(s):</label>
                <textarea name="infoAdicional" value={formData.infoAdicional} onChange={handleChange}></textarea>
            </div>

            <div class="btns-container">
                <button className='main-btn' type="button" onClick={prevStep}>Voltar</button>
                <button className='main-btn' type="submit">Próximo</button>
            </div>
        </form>
    );
};


export default FormularioAvancado;