// Header.js
import React, { useState, useEffect, useMemo, useRef } from "react";
import { Link } from "react-router-dom";
import MenuBars from "../../assets/imgs/menu-bars.svg";
import Menu from "./Menu";
import Logo from "../../assets/imgs/ethos-brasil.png";
import "./Header.css";
import menuItems from './Btns-Content';
import MenuBtn from './MenuBtn';

const Header = ({ scrollToComponent }) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleSidebar = () => {
    setIsOpen(!isOpen);
  };

  const action = (address) => {
    if (address !== "Link") {
      scrollToComponent(address);
    } else {
      scrollUp();
    }
  };

  const scrollUp = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  const memoizedMenuItems = useMemo(() => {
    return menuItems.map((menuItem, index) => (
      <div key={index}>
        <MenuBtn action={action} data={menuItem} scrollToComponent={scrollToComponent} />
      </div>
    ));
  }, [menuItems]);

  const main = process.env.REACT_APP_MAIN;

  return (
    <div
      className={`main-header scrolled header-wrapper`}
    >
      <div className="header-container header-body-footer-width">
        <div className="header-img-div">
          <div className="logo-link header-img">
            <Link to={main} onClick={scrollUp}>
              <img
                className="logo-link header-img"
                src={Logo}
                alt="logo imagem"
              ></img>
            </Link>
          </div>
        </div>
        <nav>
          {memoizedMenuItems}


          <div className="header-socials">
            <a
              href="https://www.linkedin.com/company/ethos-brasilorg/"
              target="_blank"
              rel="noreferrer"
              aria-label="site principal do escritório"
            >
              <i className="uil uil-linkedin"></i>
            </a>

            <a
              href="https://www.instagram.com/ethosbrasil_org/"
              target="_blank"
              aria-label="site principal do escritório"
              rel="noreferrer"
            >
              <i className="uil uil-instagram"></i>
            </a>

            <a
              href="mailto:contato@ethosbrasil.org"
              target="_blank"
              rel="noreferrer"
              aria-label="site principal do escritório"
            >
              <i class="uil uil-envelope"></i>
            </a>
          </div>

          <div className="header-flex">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 64 64"
              enable-background="new 0 0 64 64"
            >
              <g
                fill="none"
                stroke="#fff"
                stroke-width="2"
                stroke-miterlimit="10"
              >
                <path d="m16 1h32v62h-32z"></path>
                <path d="m28 5h8"></path>
                <path d="m16 51h32"></path>
                <path d="m16 9h32"></path>
                <circle stroke-linejoin="bevel" cx="32" cy="57" r="2"></circle>
              </g>
            </svg>

            <div className="contato-header">
              <p className="number">(84) 3033 1881</p>
              <p className="number-copy">Fale conosco</p>
            </div>
          </div>
        </nav>

        <button className="menu-toggle" onClick={toggleSidebar}>
          <img src={MenuBars} alt="Menu Bars" />
        </button>
      </div>

      <Menu
        scrollToComponent={scrollToComponent}
        toggleSidebar={toggleSidebar}
        isOpen={isOpen}
        setIsOpen={setIsOpen}
      />
    </div>
  );
};

export default Header;
