import React from 'react';
import './PontosFortes.css';

function PontosFortes() {
  return (
    <div className='pontos-fortes-container'>
      <div>
        <p>Ferramentas Avançadas</p>
        <i class="uil uil-data-sharing"></i>
      </div>
      <div>
        <p>Informações Refinadas</p>
        <i class="uil uil-comparison"></i>
      </div>
      <div>

        <p>Equipe Especializada</p>
        <i class="uil uil-users-alt"></i>
      </div>
    </div>
  );
}

export default PontosFortes;
