import React from 'react';

function Disclaimer() {
    return (
        <div className='disclaimer'>
            *Todas as informações coletadas são obtidas de fontes públicas ou legalmente autorizadas.
        </div>
    );
}

export default Disclaimer;
