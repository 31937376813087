import React, { useState } from "react";
import FormularioAvancado from "./FormularioAvancado.js";
import Agradecimento from "./Agradecimento.js";
import FormularioBasico from "./FormularioBasico.js";
import "./Formulario.css";

const Formulario = ({ scrollToContato }) => {
  const [step, setStep] = useState(1);
  const [formData, setFormData] = useState({
    nome: "",
    email: "",
    telefone: "",
    historico: "",
    alvos: "",
    qualidadePessoal: "",
    nomeAlvos: "",
    cpfCnpj: "",
    infoAdicional: "",
    interesses: [],
    estadosCidades: "",
    consideracoes: "",
  });

  const [formId, setFormId] = useState(null);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleCheckboxChange = (e) => {
    const { name, checked } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      interesses: checked
        ? [...prevState.interesses, name]
        : prevState.interesses.filter((i) => i !== name),
    }));
  };

  const handleBasicSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch('https://jacquesgomes.com.br/gb/ethos-contato-store', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          nome: formData.nome,
          email: formData.email,
          telefone: formData.telefone,
          historico: formData.historico,
          origem: "https://rips.ethosbrasil.org/"
        }),
      });
      const data = await response.json();
      setFormId(data.id);
      nextStep();
    } catch (error) {
      console.error('Erro ao enviar formulário básico:', error);
    }
  };

  const handleAdvancedSubmit = async (e) => {
    e.preventDefault();
    try {
      await fetch(`https://jacquesgomes.com.br/gb/ethos-contato-update/${formId}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          alvos: formData.alvos,
          qualidadeAlvos: formData.qualidadePessoal,
          nomeAlvos: formData.nomeAlvos,
          cpfCnpjAlvos: formData.cpfCnpj,
          interesses: formData.interesses,
          infoAdicional: formData.infoAdicional,
        }),
      });
      nextStep();
    } catch (error) {
      console.error('Erro ao enviar formulário avançado:', error);
    }
  };


  const nextStep = () => {
    setStep(step + 1);
    scrollToContato();
  };

  const prevStep = () => {
    setStep(step - 1);

    scrollToContato();
  };

  return (
    <div className="formulario-wrapper">
      {step === 1 && (
        <FormularioBasico
          formData={formData}
          handleChange={handleChange}
          handleSubmit={handleBasicSubmit}
        />
      )}
      {step === 2 && (
        <FormularioAvancado
          formData={formData}
          handleChange={handleChange}
          handleCheckboxChange={handleCheckboxChange}
          handleSubmit={handleAdvancedSubmit}
          prevStep={prevStep}
        />
      )}
      {step === 3 && <Agradecimento />}
    </div>
  );
};

export default Formulario;
