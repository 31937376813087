import React from 'react';
import './UniversoInformacoes.css';
import Informacoes from './Informacoes';

import Disclaimer from "../utils/Disclaimer";

function UniversoInformacoes() {
  return (
    <div className='UniversoInformacoes-container'>
      <div className="dossier-section-text">
        <h3 class="section-title"><b></b><span class="section-title-main">O universo de informações fornecido sobre o alvo compreende, mas não se resume a:</span><b></b></h3>
        <Informacoes />
      </div>
      <Disclaimer />
    </div>
  );
}

export default UniversoInformacoes;
