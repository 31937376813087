import React, { useState } from 'react';

const ListaInformacoes = () => {
    const [categorias, setCategorias] = useState([
        {
            titulo: 'Dados Pessoais',
            itens: [
                'Dados registrais',
                'Dados cadastrais',
                'Endereços conhecidos',
                'Telefones conhecidos',
                'E-mails conhecidos',
                'Familiares',
                'Mapa/gráfico de relações (pessoas físicas e jurídicas)',
            ],
        },
        {
            titulo: 'Dados Financeiros',
            itens: [
                'Resumo da vida financeira',
                'Renda prevista',
                'Score bancário',
                'Inscrições no SPC/SERASA',
                'Títulos protestados em cartórios',
                'Cheques sem fundo (BACEN)',
            ],
        },
        {
            titulo: 'Dados Complementares',
            itens: [
                'Menções na internet (clipping completo)',
                'Processos judiciais em todos os tribunais',
                'Menções em diários oficiais de todo o Brasil',
                'Propriedades móveis (automóveis, embarcações e aeronaves)',
                'Análise de risco (jurídico, crédito, reputacional)',
                'Candidaturas políticas',
                'Listas restritivas (ambientais, laborais, terrorismo)',
                'Pessoa Exposta Politicamente',
                'Fontes governamentais (ex. Portais da Transparência)',
                'Fontes socioambientais',
                'Antecedentes criminais (maioria dos estados)',
                'Certidões (CND-RFB, CNDT, IBAMA, SIT/MTE, CARF, CADE/SEI, TCU, CNJ, BNMP, CVM, MPF, etc)',
            ],
        },
    ]);

    return (
        <div className="container">
            {categorias.map((categoria, index) => (
                <div key={index} className="categoria">
                    <h2>{categoria.titulo}</h2>
                    <ul>
                        {categoria.itens.map((item) => (
                            <li key={item}><span>{item}</span></li>
                        ))}
                    </ul>
                </div>
            ))}
        </div>
    );
};

export default ListaInformacoes;
