import React from 'react';
import './Depoimentos.css';

function Depoimentos({ scrollToContato }) {
  return (
    <div className='depoimentos-container'>
        <div className="depoimentos-content">
         <h2>Obtenha acesso aos dados mais relevantes e atualizados!</h2>
        <button
          onClick={scrollToContato}
          className='main-btn'>entre em contato</button>
        </div>
        <div className='overlay'></div>
    </div>
  );
}

export default Depoimentos;
