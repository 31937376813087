import React from 'react';

const FormularioBasico = ({ formData, handleChange, handleSubmit }) => {

    return (
        <form onSubmit={handleSubmit}>
            <h1>FALE CONOSCO</h1>
            <div>
                <label>Nome:</label>
                <input type="text" name="nome" value={formData.nome} onChange={handleChange} required />
            </div>
            <div>
                <label>Email:</label>
                <input type="email" name="email" value={formData.email} onChange={handleChange} required />
            </div>
            <div>
                <label>Telefone:</label>
                <input type="tel" name="telefone" value={formData.telefone} onChange={handleChange} required />
            </div>
            <div>
                <label>Conte-nos um breve relato sobre o caso:</label>
                <textarea name="historico" value={formData.historico} onChange={handleChange} required></textarea>
            </div>
            <button className='main-btn' type="submit">Próximo</button>
        </form>
    );
};
export default FormularioBasico;